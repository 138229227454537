import React from 'react'

export default function DescriptionSection(props) {
    return (
        <div className="max-w-screen-xl mx-auto text-center text-black space-y-6">
            <p>What started as a dream, has caught on fire and spread without control. The vision of RastaFinance can no longer be caged,
            it has been decentralized and distributed to all corners of the world.</p>
            <p>We value transparency, love, compassion and camaraderie above all else. We stand strong as ONE, as we are determined to re-gain
            the power of our own money, taking it back from the institutions and exploiters that have kept us apart.</p>
            <p>So connect your browser-based wallet, and join the Decentralized REVOLUTION!</p>
        </div>
    )
}
